<template>
  <div>
    <div>
      <Breadcrumb>
        <template slot="restPage">
          <el-breadcrumb-item>
            <router-link
              :to="{
                name: 'CompanyList'
              }"
            >
              Company
            </router-link>
          </el-breadcrumb-item>
          <el-breadcrumb-item>
            Info
          </el-breadcrumb-item>
        </template>
      </Breadcrumb>
      <Heading heading="h4" :content="myTitle"></Heading>
      <small> View {{ myTitle }} </small>
      <hr />
      <el-form label-width="140px" v-if="info">
        <el-form-item label="Name" prop="title">
          <b class="text-success">
            {{ myTitle }}
          </b>
        </el-form-item>
        <!-- <el-form-item label="Teachers" prop="title">
          <router-link :to="{ name: 'CompanyUsers' }">
            <b>1</b>
          </router-link>
        </el-form-item> -->
        <el-form-item label="Licenses" prop="title">
          <Pro
            title="Annual Pro Accounts for Users"
            :point="info.coins"
          />
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { user, instant } from "@ivy-way/material";
import Breadcrumb from "@/components/Breadcrumb";
import Pro from "@/views/company/components/Pro.vue";
import Company from "@/views/company/apis/company.js";

export default {
  metaInfo() {
    return {
      title: "Company Info - " + this.CompanyName
    };
  },

  components: { Breadcrumb, Pro },

  mixins: [],

  props: [],
  data() {
    return {
      info: null
    };
  },
  computed: {
    user() {
      return user;
    },
    instant() {
      return instant;
    },
    myTitle() {
      return this.info ? this.info.name : "";
    }
  },
  watch: {},

  mounted() {
    this.getInfo();
  },

  methods: {
    async getInfo() {
      const res = await Company.getCompanyDetail(this.$route.params.id);
      this.info = res;
    }
  }
};
</script>

<style scoped></style>
